import React from 'react'

const SvgGoogleMaps = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <path d="M20 2h-.5L0 21.5v.5c0 1.103.897 2 2 2h.66L22 4.404V4c0-1.103-.897-2-2-2z" fill="#FDDC49" />
    <path d="M2 2C.897 2 0 2.897 0 4v17.5L19.5 2H2z" fill="#56A959" />
    <path fill="#5796FF" d="M2.66 24H19.5l-8.475-8.475z" />
    <path fill="#CCC" d="M13.508 13.008L22 21.5V4.404z" />
    <path d="M11.025 15.525L19.5 24h.5c1.103 0 2-.897 2-2v-.5l-8.492-8.492-2.483 2.517z" fill="#F2F2F2" />
    <path d="M20 2H2C.897 2 0 2.897 0 4v.25c0-1.103.897-2 2-2h18c1.103 0 2 .897 2 2V4c0-1.103-.897-2-2-2z" fill="#FFF" opacity={0.2} />
    <path d="M20 23.75H2c-1.103 0-2-.897-2-2V22c0 1.103.897 2 2 2h18c1.103 0 2-.897 2-2v-.25c0 1.103-.897 2-2 2z" opacity={0.1} />
    <path
      d="M18 0c-3.309 0-6 2.691-6 6 0 .234.016.463.042.682.208 2.132 1.348 3.888 2.556 5.747 1.187 1.828 2.414 3.717 2.912 6.17a.5.5 0 0 0 .98 0c.498-2.453 1.725-4.342 2.912-6.17 1.208-1.859 2.348-3.615 2.556-5.74.026-.226.042-.455.042-.689 0-3.309-2.691-6-6-6z"
      fill="#E53935"
    />
    <path d="M18 .25a6.11 6.11 0 0 1 5.77 4.104 6.001 6.001 0 0 0-11.539-.006A6.101 6.101 0 0 1 18 .25z" fill="#FFF" opacity={0.2} />
    <path
      d="M23.958 6.44c-.208 2.124-1.348 3.88-2.556 5.74-1.187 1.827-2.414 3.716-2.912 6.17a.5.5 0 0 1-.98 0c-.498-2.454-1.725-4.343-2.912-6.17-1.208-1.86-2.348-3.616-2.556-5.748a6.166 6.166 0 0 1-.035-.569C12.006 5.91 12 5.953 12 6c0 .234.016.463.042.682.208 2.132 1.348 3.888 2.556 5.747 1.187 1.828 2.414 3.717 2.912 6.17a.5.5 0 0 0 .98 0c.498-2.453 1.725-4.342 2.912-6.17 1.208-1.859 2.348-3.615 2.556-5.74.026-.226.042-.455.042-.689 0-.046-.006-.091-.007-.137a6.184 6.184 0 0 1-.035.577z"
      opacity={0.1}
    />
    <circle cx={18} cy={6} fill="#B71C1C" r={2} />
    <circle cx={18} cy={6} opacity={0.1} r={2} />
    <path
      d="M5.5 10.5c-1.654 0-3-1.346-3-3a3.003 3.003 0 0 1 4.802-2.399l-.6.8A2.002 2.002 0 0 0 3.5 7.5c0 1.102.897 1.999 2 1.999.93 0 1.714-.638 1.937-1.5H6V7h2.5v.5c0 1.654-1.346 3-3 3z"
      fill="#F2F2F2"
    />
    <linearGradient gradientUnits="userSpaceOnUse" id="GoogleMaps_svg__a" x1={-1.369} x2={23.936} y1={6.492} y2={18.292}>
      <stop offset={0} stopColor="#fff" stopOpacity={0.2} />
      <stop offset={1} stopColor="#fff" stopOpacity={0} />
    </linearGradient>
    <path
      d="M24 6c0-3.309-2.691-6-6-6a5.98 5.98 0 0 0-4.46 2H2C.897 2 0 2.897 0 4v18c0 1.103.897 2 2 2h18c1.103 0 2-.897 2-2V11.497c.965-1.528 1.785-3.04 1.958-4.807.026-.227.042-.456.042-.69z"
      fill="url(#GoogleMaps_svg__a)"
    />
  </svg>
)

export default SvgGoogleMaps
